/** @format */

// eslint-disable-next-line
import React, { Component, lazy, Suspense, useEffect, useState } from "react";
import "./assest/css/general.css";
import "./assest/css/style.scss";
import "./assest/css/antd/buttons.scss";
import "./assest/css/antd/inputs.scss";
import "./assest/css/antd/tables.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import LoginPage from "./page/LoginPage";
import RegisterPage from "./page/Register";
import PublicRoutes from "./utils/PublicRoutes";
import { Spin } from "antd";
import DashboardLayout from "./component/Layout/DashboardLayout";
import axios from "axios";
import { BASE_URL } from "./utils/apiConfig";
import PrivateRoutes from "./utils/PrivateRoutes";
import { useAuth } from "../context/auth-context";

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common["Authorization"] = localStorage["token"]
  ? `Bearer ${localStorage.getItem("token")}`
  : "";

const dashboardRoutes = [
  {
    path: "/",
    exact: true,
    Component: lazy(() => import("./page/Dashboard")),
    roles: [1, 2, 5],
  },
  {
    path: "/dashboard",
    exact: true,
    Component: lazy(() => import("./page/Dashboard")),
    roles: [1, 2],
  },
  {
    path: "/menu2",
    exact: true,
    Component: lazy(() => import("./page/NewPageDashboard")),
    roles: [1, 2],
  },
  {
    path: "/menu3",
    exact: true,
    Component: lazy(() => import("./page/NewPageDashboard")),
    roles: [1, 2],
  },
  {
    path: "/footer",
    exact: true,
    Component: lazy(() => import("./page/Footer")),
    roles: [1, 2],
  },
  // {
  //   path: "/enquiry-form",
  //   exact: true,
  //   Component: lazy(() => import("./page/EnquiryForm")),
  //   roles: [1],
  // },
  {
    path: "/blogs",
    exact: true,
    Component: lazy(() => import("./page/Blogs")),
    roles: [1, 2, 3],
  },
  {
    path: "/blogs-comments",
    exact: true,
    Component: lazy(() => import("./page/BlogComments")),
    roles: [1, 2, 3],
  },
  {
    path: "/blog-category",
    exact: true,
    Component: lazy(() => import("./page/Blogs/BlogsCategory")),
    roles: [1, 2, 3],
  },
  {
    path: "/blog-author",
    exact: true,
    Component: lazy(() => import("./page/Blogs/BlogsAuthor")),
    roles: [1, 2, 3],
  },
  {
    path: "/blog-settings",
    exact: true,
    Component: lazy(() => import("./page/Blogs/BlogSettings")),
    roles: [1, 2, 3],
  },
  {
    path: "/news",
    exact: true,
    Component: lazy(() => import("./page/News")),
    roles: [1, 2],
  },
  {
    path: "/contact-us",
    exact: true,
    Component: lazy(() => import("./page/ContactUs")),
    roles: [1, 2],
  },
  {
    path: "/city",
    exact: true,
    Component: lazy(() => import("./page/City")),
    roles: [1, 2, 5],
  },
  {
    path: "/branch/:city",
    exact: true,
    Component: lazy(() => import("./page/Branch")),
    roles: [1, 2, 5],
  },
  {
    path: "/wings/:branch/:city",
    exact: true,
    Component: lazy(() => import("./page/Wings")),
    roles: [1, 2, 5],
  },
  {
    path: "/menu-location",
    exact: true,
    Component: lazy(() => import("./page/MenuLocation")),
    roles: [1, 2],
  },

  {
    path: "/menu",
    exact: true,
    Component: lazy(() => import("./page/Menu")),
    roles: [1, 2],
  },
  {
    path: "/menu/:submenuId",
    exact: true,
    Component: lazy(() => import("./page/SubMenu")),
    roles: [1, 2],
  },
  {
    path: "/menu/sub-menu/:subSubMenuId",
    exact: true,
    Component: lazy(() => import("./page/SubSubMenu")),
    roles: [1, 2],
  },
  {
    path: "/menu/sub-menu/sub-sub-menu/:subSubSubMenuId",
    exact: true,
    Component: lazy(() => import("./page/SubSubSubMenu")),
    roles: [1, 2],
  },
  {
    path: "/pages",
    exact: true,
    Component: lazy(() => import("./page/Pages")),
    roles: [1, 2],
  },
  {
    path: "/hero-section",
    exact: true,
    Component: lazy(() => import("./page/Pages/PageComponents/HeroSection")),
    roles: [1, 2, 5],
  },
  {
    path: "/sub-head",
    exact: true,
    Component: lazy(() => import("./page/Pages/PageComponents/Subhead")),
    roles: [1, 2],
  },
  {
    path: "/intro-video",
    exact: true,
    Component: lazy(() => import("./page/Pages/PageComponents/IntroVideo")),
    roles: [1, 2, 5],
  },
  {
    path: "/intro-video-accodian",
    exact: true,
    Component: lazy(() => import("./page/Pages/PageComponents/IntroVideoAccodian")),
    roles: [1, 2, 5],
  },
  {
    path: "/master-count",
    exact: true,
    Component: lazy(() => import("./page/Pages/PageComponents/MasterCount")),
    roles: [1, 2, 5],
  },
  {
    path: "/localities",
    exact: true,
    Component: lazy(() =>
      import("./page/Pages/PageComponents/Localities/Localities")
    ),
    roles: [1, 2, 5],
  },
  {
    path: "/landmarks",
    exact: true,
    Component: lazy(() =>
      import("./page/Pages/PageComponents/Landmarks/Landmarks")
    ),
    roles: [1, 2, 5],
  },
  {
    path: "/apartments",
    exact: true,
    Component: lazy(() =>
      import("./page/Pages/PageComponents/Apartments/Apartments")
    ),
    roles: [1, 2, 5],
  },
  {
    path: "/curriculum",
    exact: true,
    Component: lazy(() => import("./page/Pages/PageComponents/Curriculum")),
    roles: [1, 2, 5],
  },
  {
    path: "/fee-structure",
    exact: true,
    Component: lazy(() => import("./page/Pages/PageComponents/FeeStructure")),
    roles: [1, 2, 5],
  },
  {
    path: "/faq",
    exact: true,
    Component: lazy(() => import("./page/Pages/PageComponents/FAQ")),
    roles: [1, 2, 5],
  },
  {
    path: "/testimonial",
    exact: true,
    Component: lazy(() => import("./page/Pages/PageComponents/Testimonial")),
    roles: [1, 2, 5],
  },
  {
    path: "/principal",
    exact: true,
    Component: lazy(() => import("./page/Pages/PageComponents/Principal")),
    roles: [1, 2, 5],
  },
  {
    path: "/gallery",
    exact: true,
    Component: lazy(() => import("./page/Pages/PageComponents/Gallery")),
    roles: [1, 2, 5],
  },
  {
    path: "/gallery-title-and-description",
    exact: true,
    Component: lazy(() =>
      import("./page/Pages/PageComponents/GalleryTitleAndDescription")
    ),
    roles: [1, 2, 5],
  },
  {
    path: "/infrastructure",
    exact: true,
    Component: lazy(() => import("./page/Pages/PageComponents/Infrastructure")),
    roles: [1, 2, 5],
  },
  {
    path: "/infrastructure-title-and-description",
    exact: true,
    Component: lazy(() =>
      import("./page/Pages/PageComponents/InfrastructureTitleAndDescription")
    ),
    roles: [1, 2, 5],
  },
  {
    path: "/labs",
    exact: true,
    Component: lazy(() => import("./page/Pages/PageComponents/Labs")),
    roles: [1, 2, 5],
  },
  {
    path: "/labs-title-and-description",
    exact: true,
    Component: lazy(() =>
      import("./page/Pages/PageComponents/LabTitleAndDescription")
    ),
    roles: [1, 2, 5],
  },
  {
    path: "/achievements",
    exact: true,
    Component: lazy(() => import("./page/Pages/PageComponents/Achievements")),
    roles: [1, 2, 5],
  },
  {
    path: "/special-program",
    exact: true,
    Component: lazy(() => import("./page/Pages/PageComponents/SpecialProgram")),
    roles: [1, 2, 5],
  },
  {
    path: "/curriculum-feature",
    exact: true,
    Component: lazy(() =>
      import("./page/Pages/PageComponents/CurriculumFeature")
    ),
    roles: [1, 2, 5],
  },
  {
    path: "/assessment",
    exact: true,
    Component: lazy(() => import("./page/Pages/PageComponents/Assessment")),
    roles: [1, 2],
  },
  {
    path: "/contextual-link",
    exact: true,
    Component: lazy(() => import("./page/Pages/PageComponents/ContextualLink")),
    roles: [1, 2, 5],
  },
  {
    path: "/cbse-syllabus",
    exact: true,
    Component: lazy(() => import("./page/CBSESyllabus")),
    roles: [1, 2],
  },
  {
    path: "/job-Post",
    exact: true,
    Component: lazy(() => import("./page/JobPost")),
    roles: [1, 2],
  },
  {
    path: "/job-category",
    exact: true,
    Component: lazy(() => import("./page/JobPost/JobCategory")),
    roles: [1, 2],
  },
  {
    path: "/application-recieved",
    exact: true,
    Component: lazy(() => import("./page/ApplicationRecieved")),
    roles: [1, 2],
  },
  {
    path: "/upload-file",
    exact: true,
    Component: lazy(() => import("./page/UploadFile")),
    roles: [1, 2, 3],
  },
  {
    path: "/newsletter",
    exact: true,
    Component: lazy(() => import("./page/Newsletter")),
    roles: [1, 2],
  },
  {
    path: "/users",
    exact: true,
    Component: lazy(() => import("./page/Users")),
    roles: [1],
  },
  {
    path: "/ncert-solutions",
    exact: true,
    Component: lazy(() => import("./page/NCERT")),
    roles: [1, 2],
  },
  {
    path: "/calculators",
    exact: true,
    Component: lazy(() => import("./page/Calculators")),
    roles: [1, 2],
  },
  {
    path: "/calculators/:singleCalculator",
    exact: true,
    Component: lazy(() => import("./page/IndividaulCalculator")),
    roles: [1, 2],
  },
  {
    path: "/converters",
    exact: true,
    Component: lazy(() => import("./page/Converters")),
    roles: [1, 2],
  },
  {
    path: "/formulas",
    exact: true,
    Component: lazy(() => import("./page/Formulas/Formulas")),
    roles: [1, 2, 3],
  },
  {
    path: "/formulas/sub-formulas",
    exact: true,
    Component: lazy(() => import("./page/SubFormulas/SubFormulas")),
    roles: [1, 2, 3],
  },
  {
    path: "/:singleFormula",
    exact: true,
    Component: lazy(() => import("./page/IndividualFormulas")),
    roles: [1, 2, 3],
  },
  {
    path: "/converters/:singleConverter",
    exact: true,
    Component: lazy(() => import("./page/IndividaulConverter")),
    roles: [1, 2],
  },
  {
    path: "/learning-concepts",
    exact: true,
    Component: lazy(() => import("./page/LearningConcepts")),
    roles: [1, 2],
  },
  {
    path: "/campaign",
    exact: true,
    Component: lazy(() => import("./page/Campaign")),
    roles: [1, 2, 5],
  },
  // {
  //   path: "/learning",
  //   exact: true,
  //   Component: lazy(() => import("./page/Learning")),
  //   roles: [1, 2],
  // },
  {
    path: "/sample-papers",
    exact: true,
    Component: lazy(() => import("./page/SamplePapers")),
    roles: [1, 2],
  },
  {
    path: "/poems",
    exact: true,
    Component: lazy(() => import("./page/Poems")),
    roles: [1, 2, 3],
  },
  {
    path: "/story",
    exact: true,
    Component: lazy(() => import("./page/Story")),
    roles: [1, 2, 3],
  },
  {
    path: "/event",
    exact: true,
    Component: lazy(() => import("./page/Events")),
    roles: [1, 2],
  },
  {
    path: "/event-registrations",
    exact: true,
    Component: lazy(() => import("./page/Events/RegistrationList")),
    roles: [1, 2],
  },
  {
    path: "/academic-subject",
    exact: true,
    Component: lazy(() => import("./page/AcademicSubject")),
    roles: [1, 2],
  },
  {
    path: "/academic-chapter",
    exact: true,
    Component: lazy(() => import("./page/AcademicChapter")),
    roles: [1, 2],
  },
  {
    path: "/academic-topic",
    exact: true,
    Component: lazy(() => import("./page/AcademicTopics")),
    roles: [1, 2],
  },
  {
    path: "/academic-qna",
    exact: true,
    Component: lazy(() => import("./page/AcademicQNA")),
    roles: [1, 2],
  },
  {
    path: "/other-fees",
    exact: true,
    Component: lazy(() => import("./page/Pages/PageComponents/OtherFees")),
    roles: [1, 2],
  },
  {
    path: "/syllabus-subject",
    exact: "true",
    Component: lazy(() => import("./page/SyllabusSubject/index")),
    roles: [1, 2],
  },
  {
    path: "/marketing",
    exact: "true",
    Component: lazy(() =>
      import("./page/Pages/PageComponents/BranchMarketing")
    ),
    roles: [1, 2, 5],
  },
  {
    path: "/video",
    exact: "true",
    Component: lazy(() => import("./page/Videos/index")),
    roles: [1, 2],
  },
  {
    path: "/parent-resources",
    exact: "true",
    Component: lazy(() => import("./page/ParentResources")),
    roles: [1, 2],
  },
  {
    path: "/parent-resources-category",
    exact: "true",
    Component: lazy(() =>
      import("./page/ParentResources/Category/ParentsResourceCategory")
    ),
    roles: [1, 2],
  },
  {
    path: "/parents-resources/sub-category",
    exact: "true",
    Component: lazy(() =>
      import("./page/ParentResources/SubCategory/ResourcesSubCategory")
    ),
    roles: [1, 2],
  },
  {
    path: "/public-speaking",
    exact: "true",
    Component: lazy(() => import("./page/PublicSpeaking/PublicSpeaking")),
    roles: [1, 2],
  },
  {
    path: "/football-challenge",
    exact: "true",
    Component: lazy(() => import("./page/FootballChallenge/FootballChallenge")),
    roles: [1, 2],
  },
  {
    path: "/sitelink",
    exact: "true",
    Component: lazy(() => import("./page/Sitelink/Sitelink")),
    roles: [1, 2],
  },
  {
    path: "/city-marketing",
    exact: "true",
    Component: lazy(() => import("./page/CityCampaign")),
    roles: [1, 2],
  },
  {
    path: "/activities",
    exact: "true",
    Component: lazy(() => import("./page/Activity")),
    roles: [1, 2],
  },
  {
    path: "/activities/theatre",
    exact: "true",
    Component: lazy(() => import("./page/Activity/Theatre")),
    roles: [1, 2],
  },
  {
    path: "/achievments",
    exact: "true",
    Component: lazy(() => import("./page/Achievments")),
    roles: [1, 2],
  },
];

function App() {
  const location = useLocation();
  const currUserData = useAuth();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);
  let dashboardLinks = dashboardRoutes?.map((each) => each?.path);
  return (
    <React.Fragment>
      {/* <Router basename="/"> */}
      <DashboardLayout
        visible={
          dashboardLinks?.includes(window.location.pathname) ||
          window.location.pathname.includes("/branch") ||
          window.location.pathname.includes("/wings") ||
          window.location.pathname.includes("/menu") ||
          window.location.pathname.includes("/calculators") ||
          window.location.pathname.includes("/converters") ||
          window.location.pathname.includes("formula") ||
          window.location.pathname.includes("campaign") ||
          window.location.pathname.includes("parent-resources")
        }
      >
        {/* <div
          key={location.pathname}
          className={`${transitionStage}`}
          onAnimationEnd={() => {
            if (transitionStage === "fadeOut") {
              setTransistionStage("fadeIn");
              setDisplayLocation(location);
            }
          }}
          timeout={300}
        > */}
        <Routes
        // location={displayLocation}
        >
          <Route element={<PublicRoutes />}>
            <Route exact path="login" element={<LoginPage />} />
            <Route exact path="register" element={<RegisterPage />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            {dashboardRoutes.map(({ path, Component, exact, roles }, index) => (
              <Route
                path={path}
                key={path}
                exact={exact}
                element={
                  <Suspense fallback={<Spin />}>
                    {roles?.includes(
                      Number(currUserData?.currUser?.roles?.id)
                    ) && <Component />}
                  </Suspense>
                }
              />
            ))}
          </Route>
        </Routes>
        {/* </div> */}
      </DashboardLayout>
    </React.Fragment>
  );
}

export default App;
